import $ from 'jquery';
import "slick-carousel";

export default function () {

    //
    //   Hero Carousel
    //
    //////////////////////////////////////////////////////////////////////

    $('.js-slider-hero').each(function(){
        var slider = $(this);
        slider.slick({
            autoplay: true,
            autoplaySpeed: 4500,
            arrows: true,
            dots: false,
            draggable: false,
            fade: true,
            speed: 1000,
            infinite: true,
            slidesToShow: 1
        });

        // Add click event handler for the pause button
        slider.prepend($('<button class="slick-media-control pause-button" aria-label="Pause Button"></button>').on('click', function(){
            if ($(this).hasClass('pause-button')) {
                console.log('pressed pause');
                slider.slick('slickPause'); // Pause the slider
                $(this).attr('aria-label', 'Play Button').removeClass('pause-button').addClass('play-button'); // Change aria-label to 'Play'
            } else {
                console.log('play');
                slider.slick('slickPlay'); // Resume the slider
                $(this).attr('aria-label', 'Pause Button').removeClass('play-button').addClass('pause-button'); // Change aria-label back to 'Pause'
            }
        }));
    });
}
