import $ from 'jquery';
import Enquire from "enquire.js";
import { stick_in_parent } from "../vendor/stickyKit.js";

export default function (options) {
    $.fn.stick_in_parent = stick_in_parent;
    $('.stick-parent').stick_in_parent();

   /*
   USAGE:

   DESCRIPTION
   Sticky sidebar for main-w-sidebar layout with some helpers to revise functionality as layout changes
   across breakpoints.

   OPTIONS:
   breakpoint                 A key from the APP.breakpoints object that determines when
                              to enable/disable the sticky element.
                              [object element] (required)
   offset                     Distance (in pixels) that the element should be offset from the top of the screen when stuck
                              [integer] (optional)
   stickySidebarSelector      The jQuery selector of sticky sidebar
                              [string] (required)
   */


   //
   //   Private Vars
   //
   //////////////////////////////////////////////////////////////////////

   var self = { };



   //
   //   Public Vars
   //
   //////////////////////////////////////////////////////////////////////

   self.settings = $.extend({
      parent: $('body'),
      stickyElementSelector: null,
      breakpoint: null,
      offset: 0
   }, options);



   //
   //   Private Methods
   //
   //////////////////////////////////////////////////////////////////////

   var _init = function() {
      _stickyKitInit();
      _addEventListeners();
   };

   var _addEventListeners = function() {
      _listenForMediaQueries();
   }

   var _stickyKitInit = function() {
      $(self.settings.stickyElementSelector).stick_in_parent({
         parent: $('body'),
         offset_top: self.settings.offset
      });
   }

   var _listenForMediaQueries = function() {
      Enquire.register('screen and (max-width:'+ self.settings.breakpoint +'px)', {
         match : function() {
            $(self.settings.stickyElementSelector).trigger("sticky_kit:detach");
         },
         unmatch : function() {
            _stickyKitInit();
         }
      });
   };


   //
   //   Public Methods
   //
   //////////////////////////////////////////////////////////////////////

   // Initiate
   _init();

   // Return the Object
   return self;
}
