import MatchHeight from "../modules/match_height";
import $ from 'jquery';

export default function () {

   //
   //   Match Height
   //
   //////////////////////////////////////////////////////////////////////

   new MatchHeight({
      $el: $('.js-match-height-friends')
   });

   new MatchHeight({
      $el: $('.js-match-height-special')
   });

}
