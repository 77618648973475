import '/src/styles/main.scss';

import OneRouter from 'one-router';
import './vendor/modernizr.js';
import Menu from './modules/menu';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}


//
//   Global App Variable
//
//////////////////////////////////////////////////////////////////////

window.APP = window.APP || {};

// Routes
//--------------------------------------------------------------------

import global_route from "./routes/global_route.js";
import blog_route from "./routes/blog_route.js";
import index_route from "./routes/index_route.js";
import locations_route from "./routes/locations_route.js";
import menus_route from "./routes/menus_route.js";
import wine_awards_route from "./routes/wine_awards_route.js";
import wine_special_events_route from "./routes/wine_special_events_route.js";
import wine_wine_trips_route from "./routes/wine_wine_trips_route.js";
import tasting_room_notes_route from "./routes/tasting_room_notes_route.js";

APP.globalRoute = global_route;
APP.blogRoute = blog_route;
APP.indexRoute = index_route;
APP.locationsRoute = locations_route;
APP.menusRoute = menus_route;
APP.wineAwardsRoute = wine_awards_route;
APP.wineSpecialEventsRoute = wine_special_events_route;
APP.wineWineTripsRoute = wine_wine_trips_route;
APP.tastingRoomNotes = tasting_room_notes_route;


// Breakpoints
//--------------------------------------------------------------------
// Should match the $mq-breakpoints sass map.
// Defined in /styles/base/_variables.scss
APP.breakpoints = {
  medium: '750',
  small: '600'
}

// Pub/Sub Events
//--------------------------------------------------------------------
APP.pubSubEvents = {
  mqSidebar: 'mqSidebar'
};



//
//   App Initiation
//
//////////////////////////////////////////////////////////////////////

APP.init = function() {
  // Configure Routing
  // Each route should be defined in a routename_route.js file in /scripts/modules/routes
  var routes = {
    '/$': APP.indexRoute,
    '/blog$': APP.blogRoute,
    '/locations': APP.locationsRoute,
    '/menus$': APP.menusRoute,
    '/wine/awards': APP.wineAwardsRoute,
    '/wine-club/special-events': APP.wineSpecialEventsRoute,
    '/wine-club/wine-trips': APP.wineWineTripsRoute,
    '/tasting-room/monthly-menu-notes': APP.tastingRoomNotes,
  };

  APP.router = new OneRouter(routes);

  // Trigger Global Setup
  APP.globalRoute();
};


//
//   Kickoff
//
//////////////////////////////////////////////////////////////////////

APP.init();
document.addEventListener('afterBlitzInject', function (event) {
  new Menu();
});
