import $ from 'jquery';

/*
  Controls funtionality of the Reservations Widget
*/

export default function (options) {


    //---------------------------------------------------------------
    // Private Variables
    //---------------------------------------------------------------
    var self = {
        $widget: $('.js-reservation-widget'),
        $widgetBtn: $('.js-reservation-widget-btn'),
        $widgetBtnFooter: $('.js-reservations-footer-btn'),
        timerOpen: 800,
        timerClose: 8000,
        scrollTolerance: 200,
        scrollTop: 0,
    };


    //---------------------------------------------------------------
    // Initialize and Attach Event Listeners
    //---------------------------------------------------------------
    var init = function () {
        addEventListeners();

        if ($('body').hasClass('home')) {
            checkForSmallScreen();
            addAnimation();
            addCloseOnScroll();
        }
    };

    var addEventListeners = function () {
        $(self.$widgetBtn).click(onClickWidgetBtn);
        $(self.$widgetBtnFooter).click(onClickWidgetBtn);
        $(self.$widget).click(clearAutoEvents);
    };


    //---------------------------------------------------------------
    // Private Methods
    //---------------------------------------------------------------
    var addAnimation = function () {
        self.timerOpen = setTimeout(function () {
            toggleWidgetState();
        }, self.timerOpen);

        self.timerClose = setTimeout(function () {
            toggleWidgetState();
            clearAutoEvents();
        }, self.timerClose);
    }

    var addCloseOnScroll = function () {
        // On scroll check for auto-close
        $(window).bind('scroll.closeOnScroll', closeOnScroll);
    }

    var checkForSmallScreen = function () {
        if ($(window).width() < 600) {
            self.timerClose = 2500;
        }
    }

    var closeOnScroll = function (scrollTop) {
        // If user scrolls beyond the scrollTolerance close the reservation widget and unbind listener
        if (($(window).scrollTop() > self.scrollTop + self.scrollTolerance) || (self.scrollTop > $(window).scrollTop() + self.scrollTolerance)) {
            // Add slight delay so things can register on page load
            self.timerOpen = setTimeout(function () {
                clearAutoEvents();
                toggleWidgetState();
            }, 20);
        }
        ;
    }

    var clearAutoEvents = function () {
        clearTimeout(self.timerOpen);
        clearTimeout(self.timerClose);
        $(self.$widget).unbind();
        $(window).unbind('scroll.closeOnScroll');
    }

    var onClickWidgetBtn = function (evt) {
        toggleWidgetState();
        clearAutoEvents();
        evt.preventDefault();
    }

    var toggleWidgetState = function (evt) {
        $(self.$widget).toggleClass('is-active');
    }


    //---------------------------------------------------------------
    // Self Initialize
    //---------------------------------------------------------------
    init();

}
