import MatchHeight from "../modules/match_height";
import $ from 'jquery';

export default function () {

   //
   //   Match Height
   //
   //////////////////////////////////////////////////////////////////////

   new MatchHeight({
      $el: $('.js-match-height-menus')
   });

}
