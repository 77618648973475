import MatchHeight from "../modules/match_height";
import InfiniteScroll from "infinite-scroll";
import $ from 'jquery';

export default function () {

   //
   //   Match Height
   //
   //////////////////////////////////////////////////////////////////////

   var _matchBlogCapsuleHeight = function() {
      new MatchHeight({
         $el: $('.js-match-height-blog-posts')
      });
   };

   _matchBlogCapsuleHeight();


   //
   //   Infinite Scroll
   //
   //////////////////////////////////////////////////////////////////////

   var $blogNextBtn = $('.js-blog-infinite-scroll-next');
   if ($blogNextBtn.length) {

      var blogInfiniteScroll = new InfiniteScroll('.js-blog-infinite-scroll', {
        path: '.js-blog-infinite-scroll-next',
        append: '.js-blog-capsule',
        history: false
      });

      blogInfiniteScroll.on('append', _matchBlogCapsuleHeight);
   }

}
