import $ from 'jquery';

export default function (options) {
   /*
   USAGE:

   DESCRIPTION
   Adds ajax support to Freeform forms (i.e. Craft form plugin)

   OPTIONS:
   $ajaxForm                  The jQuery object containing the form
                              [jQuery Object] (required)
   $formWrapper               The jQuery object of the form's parent wrapping element
                              [jQuery Object] (required)
   */


   //
   //   Private Vars
   //
   //////////////////////////////////////////////////////////////////////

   var self = {
      form: null,
      $form: null,
      stickyHeaderOffset: $('.js-site-header').height() + 80
   };



   //
   //   Public Vars
   //
   //////////////////////////////////////////////////////////////////////

   self.settings = $.extend({
      ajaxFormSelector: null,
      $ajaxFormExtras: null,
      $confirmation: $('.js-form-confirmation'),
      $formWrapper: null
   }, options);



   //
   //   Private Methods
   //
   //////////////////////////////////////////////////////////////////////

   var _init = function() {
      self.form = document.getElementsByClassName(self.settings.ajaxFormSelector)[0];
      self.$form = $(self.form);
      _addEventListeners();
   };

   var _addEventListeners = function() {
      self.form.addEventListener('freeform-ready', _onFreeFormReady);
   };

   var _onFreeFormReady = function(event) {
      var freeform = event.target.freeform;

      // On successful form submission
      freeform.addOnSuccessfulAjaxSubmit(function(event, form, response) {
         _onSuccess(response);
      });

      // On failed form submission
      freeform.addOnFailedAjaxSubmit(function(event, form, response) {
         // Call onError with a delay because Freeform doesn't appear to give us a proper callback
         setTimeout(_onError, 50);
      });

      freeform.addOnSubmitCallback(function(formElement, options) {
         // Disabled submit button
         _submitDisabled();
         // Return true so submission process continues
         return true
      });
   };

   var _onSuccess = function(response) {
      // Calculate the position of the first error (but account for sticky header)
      var scrollPosition = self.settings.$formWrapper.offset().top - self.stickyHeaderOffset - 200;

      // Scroll to top of the form
      $('html, body').animate({ scrollTop: scrollPosition }, 1200, 'easeInOutExpo');

      // Fade off form
      if(self.settings.$ajaxFormExtras) {
         self.settings.$ajaxFormExtras.fadeOut(500);
      }

      // Reveal success message
      self.$form.delay(150).fadeOut(500, _revealConfirmationMessage);
   };

   var _revealConfirmationMessage = function() {
      // Grab confirmation message
      var $confirmation = self.settings.$confirmation;

      // Remove any added messages
      if(self.settings.$ajaxFormExtras) {
         self.settings.$ajaxFormExtras.remove();
      }

      // Reveal message
      self.settings.$formWrapper.html($confirmation).hide().fadeIn(2000);
   };

   var _onError = function() {
      // Calculate the position of the first error (but account for sticky header)
      var scrollPosition = $('.ff-errors').first().offset().top - self.stickyHeaderOffset;

      // Scroll to first error
      $('html, body').animate({ scrollTop: scrollPosition }, 1200, 'easeInOutExpo');

      // Enable submit button
      _submitEnabled();
   };

   var _submitDisabled = function() {
      self.$form.find('button[type=submit]').attr('disabled', 'disabled').addClass('is-disabled');
   };

   var _submitEnabled = function() {
      self.$form.find('button[type=submit]').removeAttr("disabled").removeClass('is-disabled');
   };


    //
    //   Public Methods
   //
   //////////////////////////////////////////////////////////////////////

   // Initiate
   _init();

   // Return the Object
   return self;
}
