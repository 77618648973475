import $ from 'jquery';

export default function (options) {
   /*
   OPTIONS:
   $el                 A collection of jQuery elements to match
                       [jQuery Object] (required)
   minHeight           A minimum height each element can have
                       [Number] (optional)
   pubSubEvent         The pubSubEvent we listen for to know when to match/unmatch heights
                       [key in APP.pubSubEvents object] (optional)                      
   */
   
   //
   //   Private Vars
   //
   //////////////////////////////////////////////////////////////////////
   
   var self = {
      shouldMatchHeights: true
   };
   
   
   //
   //   Public Vars
   //
   //////////////////////////////////////////////////////////////////////
   
   self.settings = $.extend({
      minHeight: 0,
      pubSubEvent: null
   }, options);
   
   
   //
   //   Private Methods
   //
   //////////////////////////////////////////////////////////////////////
   
   var _init = function() {
      _addEventListeners();

      setTimeout(function() {
         _onResize();
      }, 2000);
   };
   
   var _addEventListeners = function() {
      $(window).on('resize', _onResize).trigger('resize');

      // If pubsub event was provided, listen for change
      if (typeof self.settings.pubSubEvent === 'string') {
         PubSub.subscribe(self.settings.pubSubEvent, _onMqChange);
      }
   };
   
   var _onMqChange = function(msg, mqStatus) {
      if (mqStatus) {
         self.shouldMatchHeights = true;
      } else {
         self.shouldMatchHeights = false;
      }
   }

   var _reset = function() {
      self.settings.$el.css({'min-height':'0'});
   }

   var _onResize = function(msg, mqStatus) {
      if (self.shouldMatchHeights) {
         _reset();
         self.match();
      } else {
         self.unmatch();
      }
   }

   
   //
   //   Public Methods
   //
   //////////////////////////////////////////////////////////////////////
   
   self.match = function() {
      var maxHeight = self.settings.minHeight;

      // Find the tallest item
      self.settings.$el.each(function() {
         maxHeight = maxHeight > $(this).outerHeight() ? maxHeight : $(this).outerHeight();
      });

      // Update all items to this height
      self.settings.$el.css({ 'min-height': maxHeight + 'px' });
   };
   
   self.unmatch = function() {
      _reset();
   };

   
   //
   //   Initialize
   //
   //////////////////////////////////////////////////////////////////////
   
   _init();
   
   // Return the Object
   return self;
}
