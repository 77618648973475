import Accordion from "../modules/accordion";
import $ from 'jquery';

export default function () {

   //
   //   Awards Accordion
   //
   //////////////////////////////////////////////////////////////////////

   new Accordion({
      accordion: $('.js-awards-accordion'),
      bodyIsNested: true
   });


   //
   //   Set Awards Totals
   //
   //////////////////////////////////////////////////////////////////////

      $('.js-accordion-header').each(function(index) {
         var total = $(this).next().find('.js-award').length;
         var label = total > 1 ? ' Awards' : ' Award';

         $(this).find('.js-total-awards').html(total + label);
      });

}
