import $ from 'jquery';
import 'jquery.easing';

export default function (options) {

   /*

   DESCRIPTION
   Adds accordion functionality to specified object.

   OPTIONS:
   accordion                  The jQuery accordion object 
                              [object] (required)
   accordionHeaderSelector    The jQUery selector for the accordion header
                              [string] (optional)              
   accordionBodySelector      The jQUery selector for the accordion body
                              [string] (optional)           
   */


   //
   //   Private Vars
   //
   //////////////////////////////////////////////////////////////////////

   var self = {
      $accordionHeader: null,
      $accordionContent: null
   };


   //
   //   Public Vars
   //
   //////////////////////////////////////////////////////////////////////

   self.settings = $.extend({
      accordion: null,
      bodyIsNested: false,
      accordionHeaderSelector: '.js-accordion-header',
      accordionBodySelector: '.js-accordion-body'
   }, options);



   //
   //   Private Methods
   //
   //////////////////////////////////////////////////////////////////////

   var init = function() {
      self.$accordionHeader = self.settings.accordion.find(self.settings.accordionHeaderSelector);
      addEventListeners();
   };

   var addEventListeners = function() {
      self.$accordionHeader.each(function(index) {
         $(this).click(onClickRevealContent);
         $(this).on('keydown', function(e) {
            if(e.which === 13) {
               $(this).click();
            }
         });
         toggleBody($(this), 0);
      });
   };

   var onClickRevealContent = function(evt){
      $(this).toggleClass('is-open');
      $(this).attr('aria-expanded', $(this).hasClass('is-open') ? 'true' : 'false');
      toggleBody($(this), 800);
      return false;
   }

   var toggleBody = function(accordionHeader, speed) {
      if(self.settings.bodyIsNested) {
         $(accordionHeader).next().find(self.settings.accordionBodySelector).stop().slideToggle(speed, 'easeInOutExpo');
      } else {
         $(accordionHeader).next(self.settings.accordionBodySelector).stop().slideToggle(speed, 'easeInOutExpo');        
      }
   }


   //
   //   Public Methods
   //
   //////////////////////////////////////////////////////////////////////

   init();

}
