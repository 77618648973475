import MakeSticky from "../modules/make_sticky";

export default function () {

   //
   //   Match Height
   //
   //////////////////////////////////////////////////////////////////////

   new MakeSticky({
      stickyElementSelector: '.js-sticky',
      breakpoint: APP.breakpoints.medium,
      offset: 110
   });

}
