import $ from 'jquery';
import {togglePushy, closePushy, openPushyFallback, toggleSubmenu, closeOnKeyUp} from "../vendor/pushy.js";
import "../vendor/doubleTapToGo.js";

export default function () {
    var init = function() {
        $('.js-nav-primary').doubleTapToGo();

        let hamburgers = document.querySelectorAll('.js-btn-hamburger');
        hamburgers.forEach((burger) => {
            burger.addEventListener('click', togglePushy);
        });
    }

    init();
}