import $ from 'jquery';
import {togglePushy, closePushy, openPushyFallback, toggleSubmenu, closeOnKeyUp} from "../vendor/pushy.js";
import "lazysizes";
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'magnific-popup';
import 'slick-carousel';
import Accordion from "../modules/accordion";
import anchorScroll from "anchor-scroll";
import "../vendor/doubleTapToGo.js";
import FitVids from "fitvids";
import FreeformAjax from "../modules/freeform_ajax";
import MakeSticky from "../modules/make_sticky";
import ReservationsWidget from "../modules/reservations_widget";
import YouTubePlayList from "../modules/youtube_playlist";

export default function() {


    //
    //   Accordion
    //
    //////////////////////////////////////////////////////////////////////

    $('.js-accordion').each(function() {
        new Accordion({
            accordion: $(this)
        });
    });


    //
    //   Double Tap to Go
    //
    //////////////////////////////////////////////////////////////////////

    $('.js-nav-primary').doubleTapToGo();


    //
    //   Fitvids
    //
    //////////////////////////////////////////////////////////////////////

    FitVids();


    //
    //   Freeform (AJAX)
    //
    //////////////////////////////////////////////////////////////////////
    var $form = $('.js-form-ajax');

    if ($form.length) {
        new FreeformAjax({
            $ajaxFormExtras: $('.js-form-ajax-extras'),
            $formWrapper: $('.js-form-wrapper'),
            ajaxFormSelector: 'js-form-ajax'
        });
    }


    //
    //   Popup Window
    //
    //////////////////////////////////////////////////////////////////////
    let youtubePlaylist = document.querySelectorAll('.js-popup-youtube-playlist');

    if (youtubePlaylist && youtubePlaylist.length > 0) {
        $('.js-popup-youtube-playlist').magnificPopup({
            type: 'ajax',
            mainClass: 'mfp--content-inset mfp-fade',
            removalDelay: 600,
            preloader: false,
            fixedContentPos: true,
            fixedBgPos: true,
            ajax: {
                settings: {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }
            },
            callbacks: {
                ajaxContentAdded: function() {
                    try {
                        new YouTubePlayList();
                    } catch (error) {
                        console.error('Error initializing YouTubePlayList:', error);
                    }
                },
            }
        });
    } else {
        console.log('No elements found with class .js-popup-youtube-playlist');
    }

    let videoPopups = document.querySelectorAll('.js-popup-video, .js-virtual-tour');
    if (videoPopups && videoPopups.length > 0) {
        $('.js-popup-video, .js-virtual-tour').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp--popup mfp-fade',
            removalDelay: 600,
            preloader: false,
            fixedContentPos: true,
            fixedBgPos: true
        });
    }

    let galleries = document.querySelectorAll('.js-gallery');
    if (galleries && galleries.length > 0) {
        $('.js-gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            mainClass: 'mfp--popup mfp-fade',
            tClose: 'Close (Esc)', // Alt text on close button
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 2], // Will preload 0 - before current, and 1 after the current image
                tPrev: 'Previous (Left arrow key)', // Alt text on left arrow
                tNext: 'Next (Right arrow key)', // Alt text on right arrow
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            }
        });
    }


    //
    //   Reservations Widget
    //
    //////////////////////////////////////////////////////////////////////

    new ReservationsWidget();


    //
    //   Slick Slider / Carousel
    //
    //////////////////////////////////////////////////////////////////////

    $('.js-slider').each(function() {
        var slider = $(this);
        slider.slick({
            autoplay: true,
            autoplaySpeed: 3500,
            arrows: true,
            dots: false,
            draggable: true,
            fade: true,
            speed: 1600,
            infinite: true,
            slidesToShow: 1
        });

        // Add click event handler for the pause button
        slider.prepend($('<button class="slick-media-control pause-button" aria-label="Pause Button"></button>').on('click', function() {
            if ($(this).hasClass('pause-button')) {
                console.log('pressed pause');
                slider.slick('slickPause'); // Pause the slider
                $(this).attr('aria-label', 'Play Button').removeClass('pause-button').addClass('play-button'); // Change aria-label to 'Play'
            } else {
                console.log('play');
                slider.slick('slickPlay'); // Resume the slider
                $(this).attr('aria-label', 'Pause Button').removeClass('play-button').addClass('pause-button'); // Change aria-label back to 'Pause'
            }
        }));
    });


    //
    //   Smooth Anchor Scrolling
    //
    //////////////////////////////////////////////////////////////////////

    // Smooth scroll to anchor links
    anchorScroll.init({
        updateUrl: false,
        offset: 0,
        ease: 'in-out-expo',
        duration: 800,
        selector: '.js-anchor-scroll'
    });


    //
    //   Sticky Elements
    //
    //////////////////////////////////////////////////////////////////////
    const stickyElements = document.querySelectorAll('.js-sticky');
    if (stickyElements && stickyElements.length > 0) {
        new MakeSticky({
            stickyElementSelector: '.js-sticky',
            breakpoint: APP.breakpoints.small,
            offset: 110
        });
    }


    //
    //   YouTube Playlist
    //
    //////////////////////////////////////////////////////////////////////

    $('.js-youtube-playlist').each(function() {
        new YouTubePlayList();
    });


}
